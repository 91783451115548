<template>
  <div class="flex-column d-flex align-content-space-between">
    <b-row v-for="(_, index) in value_from_prop"
           :key="index"
           class="space-between-rows">

      <b-col v-for="option in options"
             :key="option.model"
             class="no-stack-column">
        <b-input-group :append="option.append"
                       :prepend="option.prepend"
                       class="mb-1">
          <b-form-input v-model="value_from_prop[index][option.model]"
                        :class="{'invalid': value_from_prop[index][option.model] === ''}"
                        :readonly="disabled"
                        :type="option.type"
                        v-bind="option.prop"/>
        </b-input-group>
      </b-col>

      <b-col cols="auto pl-0 d-flex">
        <base-icon-round-button-remove v-if="value_from_prop.length > 1 && !disabled"
                                       class="align-center my-auto"
                                       @click="removeSelected(index)"/>
      </b-col>
    </b-row>

    <base-icon-round-button-add v-if="multiselect && !disabled"
                                class="justify-center mx-auto"
                                @click="addEmpty"/>
  </div>
</template>

<script>
  import BaseIconRoundButtonAdd from '@/components/baseComponents/baseIcons/BaseIconRoundButtonAdd';
  import BaseIconRoundButtonRemove from '@/components/baseComponents/baseIcons/BaseIconRoundButtonRemove';

  export default {
    components: {
      BaseIconRoundButtonRemove,
      BaseIconRoundButtonAdd,
    },
    props: {
      value: [String, Array],
      disabled: {
        type: Boolean,
        default: false,
      },
      multiselect: {
        type: Boolean,
        default: true,

      },
      options: {
        type: Object,
        required: true,
      },
    },
    data() {
      return { value_from_prop: this.value };
    },
    created() {
      if (this.value_from_prop === undefined) {
        this.value_from_prop = [];
        this.addEmpty();
        this.$emit('input', this.value_from_prop);
      }
    },
    methods: {
      addEmpty() {
        this.value_from_prop.push(
          Object.fromEntries(Object.entries(this.options).map((el) => [el[1].model, ''])),
        );
      },
      removeSelected(index) {
        this.value_from_prop.splice(index, 1);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .invalid {
    border: 2px solid red;
  }

  .no-stack-column {
    min-width: 250px;
  }

  .space-between-rows {
    margin-bottom: 0.5rem;
    @media (max-width: 950px) {
      margin-bottom: 1.5rem;
    }
  }
</style>
