<template>
  <div>
    <b-card body-bg-variant="light"
            header="General information"
            header-bg-variant="dark"
            header-text-variant="light">

      <energy-range v-model="valueFromProp"
                    :beamline-specification="phelix_specification"
                    :disabled="!editable"
                    :is_photon_energy_from_invalid="$v.valueFromProp.photon_energy_from"
                    :is_photon_energy_to_invalid="$v.valueFromProp.photon_energy_to"></energy-range>

      <label>Photon polarization</label>
      <multiselect v-model="valueFromProp.photon_polarisation"
                   :disabled="!editable"
                   :isInvalid="$v.valueFromProp.photon_polarisation"
                   :options="photon_polarisation"></multiselect>

      <label>Detection modes</label>
      <multiselect v-model="valueFromProp.detection_modes"
                   :disabled="!editable"
                   :isInvalid="$v.valueFromProp.detection_modes"
                   :options="detection_modes"
                   :stacked="true"
                   class="detection_modes"/>

      <template v-if="isExpertCommissioningAccessModeAlertShown">
        <b-alert
            :show="true"
            class="alert"
            variant="danger">
          <h5 class="font-weight-bold">
            Remember that SR-ARPES is currently available ONLY in Expert Commissioning Access mode.
            For more information visit this
            <a class="sol-link"
               href="https://synchrotron.uj.edu.pl/en_GB/uzytkownicy/nabory-wnioskow"
               rel="noopener noreferrer"
               target="_blank">
              website</a>.
          </h5>
        </b-alert>
      </template>

      <temperature-range v-model="valueFromProp"
                         :beamline-specification="phelix_specification"
                         :disabled="!editable"
                         :value_from_invalid="$v.valueFromProp.measurement_temperature_from"
                         :value_to_invalid="$v.valueFromProp.measurement_temperature_to">
      </temperature-range>

      <p class="p-form-label">Do you want to use cryogenic liquid?</p>
      <multiselect v-model="valueFromProp.cryogenic_liquids"
                   :disabled="!editable"
                   :isInvalid="this.$v.valueFromProp.cryogenic_liquids"
                   :options="cryogenic_liquids"
                   unselectBtn="None"/>

      <template v-if="hasProperty(valueFromProp, 'cryogenic_liquids')
                  && valueFromProp.cryogenic_liquids.find(el => el === 'LHe')">
        <p class="p-form-label">Please clarify why do you need liquid helium</p>
        <p class="p-form-description">(max 300 characters incl. spaces)</p>
        <b-form-textarea v-model="valueFromProp.lhe_use_justification"
                      :class="{'invalid': $v.valueFromProp.lhe_use_justification.$error}"
                      placeholder="Enter missing value"
                      @input="$v.valueFromProp.lhe_use_justification.$touch()"/>
      </template>
    </b-card>

    <b-card body-bg-variant="light"
            header="Sample preparation and environment"
            header-bg-variant="dark"
            header-text-variant="light">

      <preparation-in-situ v-model="valueFromProp"
                           :beamline-specification="phelix_specification"
                           :disabled="!editable"
                           :is_preparation_in_situ_invalid="$v.valueFromProp.preparation_in_situ"
                           :is_preparation_temperature_from_invalid="$v.valueFromProp.preparation_temperature_from"
                           :is_preparation_temperature_to_invalid="$v.valueFromProp.preparation_temperature_to"></preparation-in-situ>

      <label>Evaporation</label>
      <extended-radio-group v-model="valueFromProp.evaporation"
                            :disabled="!editable"
                            :isInvalid="$v.valueFromProp.evaporation"
                            :options="yesNo"></extended-radio-group>
      <label v-if="valueFromProp.evaporation"
             class="grey--text">
        Contact beamline scientist about actually used materials.</label>
      <two-data-list v-if="valueFromProp.evaporation"
                     v-model="valueFromProp.evaporation_data"
                     :disabled="!editable"
                     :multiselect=true
                     :options="evaporation_options"></two-data-list>

      <label>Ar-sputtering</label>
      <extended-radio-group v-model="valueFromProp.ar_sputtering"
                            :disabled="!editable"
                            :isInvalid="$v.valueFromProp.ar_sputtering"
                            :options="yesNo"></extended-radio-group>

      <label>Crystal clever</label>
      <extended-radio-group v-model="valueFromProp.crystal_clever"
                            :disabled="!editable"
                            :isInvalid="$v.valueFromProp.crystal_clever"
                            :options="yesNo"></extended-radio-group>

      <label>Reaction chamber</label>
      <extended-radio-group v-model="valueFromProp.reaction_chamber"
                            :disabled="!editable"
                            :isInvalid="$v.valueFromProp.reaction_chamber"
                            :options="yesNo"></extended-radio-group>

      <template v-if="valueFromProp.reaction_chamber">
        <p class="grey--text font-weight-bold"
           v-html="reactionChamberDataSpecification"/>
        <label v-if="reactionChamberRangeValidatorError"
               class="red--text">
          Check if all values are not out of range.
        </label>
        <three-data-list v-model="valueFromProp.reaction_chamber_data"
                         :disabled="!editable"
                         :options="reaction_chamber_options"/>
      </template>
    </b-card>
  </div>
</template>

<script>
  import { bm_phelix_validator } from '@/assets/validators/bm_phelix_validator';
  import { notation_number_regex } from '@/assets/validators/regex_validator';
  import extendedRadioGroup from '@/components/extended-radio-group';
  import beamlineSpecification from '@/json/beamlineSpecification';
  import energyRange from '@/components/proposal/proposals/energy-range';
  import temperatureRange from '@/components/proposal/proposals/temperature-range';
  import preparationInSitu from '@/components/proposal/proposals/preparation_in_situ';
  import twoDataList from '@/components/two_data_list';
  import threeDataList from '@/components/three_data_list';
  import multiselect from '@/components/multiselect';
  import yesNo from '@/json/radioButtonYesNo';
  import { adaptListOfNamesToMultiselectOptions } from '@/helpers';

  export default {
    components: {
      extendedRadioGroup,
      energyRange,
      temperatureRange,
      preparationInSitu,
      twoDataList,
      multiselect,
      threeDataList,
    },
    props: ['value', 'editable'],
    computed: {
      reactionChamberRangeValidatorError() {
        const allFieldsFilled = this.$v.valueFromProp.reaction_chamber_data.notEmptyField;
        const rangeValidationError = !this.$v.valueFromProp.reaction_chamber_data.rangeValidation;
        return allFieldsFilled && rangeValidationError;
      },
      reactionChamberDataSpecification() {
        const consultGasUsage = 'Consult gas usage with beamline manager!';

        const minTemperature = this.phelix_specification.reaction_chamber_temperature.from;
        const maxTemperature = this.phelix_specification.reaction_chamber_temperature.to;
        const temperatureRangeText = `Reaction temperature range: ${minTemperature} - ${maxTemperature} K`;

        const minPressure = this.phelix_specification.reaction_chamber_gas_pressure.from;
        const maxPressure = this.phelix_specification.reaction_chamber_gas_pressure.to;
        const pressureRangeText = `Gas pressure range: ${minPressure} - ${maxPressure} mbar`;

        return `${consultGasUsage} </br></br> ${pressureRangeText} </br> ${temperatureRangeText}`;
      },
      isExpertCommissioningAccessModeAlertShown() {
        return this.valueFromProp.detection_modes?.length && this.valueFromProp.detection_modes.includes(
          'In Expert Commissioning Access: Spin Resolved Angle Resolved Photoelectron Spectroscopy (SR-ARPES)',
        );
      },
    },
    data() {
      return {
        phelix_specification: beamlineSpecification.phelix_specification,
        valueFromProp: this.value,
        yesNo,
        evaporation_options: {
          first: { prepend: 'Material' },
          second: {
            prepend: 'Thickness',
            append: 'nm',
            type: 'text',
          },
        },
        reaction_chamber_options: {
          first: {
            prepend: 'Gas type',
            type: 'text',
            model: 'gas_type',
          },
          second: {
            prepend: 'Gas pressure',
            append: 'mbar',
            type: 'text',
            model: 'gas_pressure',
            prop: {
              formatter(value) {
                const { target } = window.event;
                const test_value = value.replace(',', '.').trim();
                return notation_number_regex.regex.test(test_value)
                  ? value : value.substr(0, target.selectionEnd - 1) + value.substr(target.selectionEnd);
              },
            },
          },
          third: {
            prepend: 'Reaction temperature',
            append: 'K',
            type: 'number',
            model: 'reaction_temperature',
            prop: {
              min: beamlineSpecification.phelix_specification.reaction_chamber_temperature.from,
              max: beamlineSpecification.phelix_specification.reaction_chamber_temperature.to,
            },
          },
        },
        photon_polarisation: [
          {
            name: 'Linear horizontal',
            buttonVariant: 'outline-info',
          },
          {
            name: 'Circular / Eliptical',
            buttonVariant: 'outline-info',
          },
          {
            name: 'Linear vertical',
            buttonVariant: 'outline-info',
          },
        ],
        cryogenic_liquids: adaptListOfNamesToMultiselectOptions([
          'None', 'LN2', 'LHe',
        ]),
        detection_modes: [
          {
            name: 'Resonance Photoelectron Spectroscopy (ResPES)',
            buttonVariant: 'outline-info',
          },
          {
            name: 'Soft X-ray Angle Resolved Photoelectron Spectroscopy (SX-ARPES)',
            buttonVariant: 'outline-info',
          },
          {
            name: 'In Expert Commissioning Access: Spin Resolved Angle Resolved Photoelectron Spectroscopy (SR-ARPES)',
            buttonVariant: 'outline-info',
          },
          {
            name: 'X-ray Photoelectron Spectroscopy (XPS)',
            buttonVariant: 'outline-info',
          },
          {
            name: 'Circular Dichroic Angle Resolved Photoelectron Spectroscopy (CD-ARPES)',
            buttonVariant: 'outline-info',
          },
          {
            name: 'Total Fluorescence Yield (TFY)',
            buttonVariant: 'outline-info',
          },
          {
            name: 'Total Electron Yield (TEY)',
            buttonVariant: 'outline-info',
          },
        ],
      };
    },
    validations: bm_phelix_validator,
    watch: {
      valueFromProp() {
        this.$emit('input', this.valueFromProp);
        this.$emit('beamline_validation', this.$v.valueFromProp);
      },
      'valueFromProp.cryogenic_liquids': {
        handler() {
          if (!this.isLHeChecked()) {
            delete this.valueFromProp.lhe_use_justification;
          }
        },
      },
      'valueFromProp.evaporation': {
        handler() {
          if (!this.valueFromProp.evaporation && this.valueFromProp.evaporation_data) {
            delete this.valueFromProp.evaporation_data;
          }
        },
      },
      'valueFromProp.reaction_chamber': {
        handler() {
          if (!this.valueFromProp.reaction_chamber && this.valueFromProp.reaction_chamber_data) {
            delete this.valueFromProp.reaction_chamber_data;
          }
        },
      },
    },
    created() {
      this.$emit('beamline_validation', this.$v.valueFromProp);
    },
    methods: {
      isLHeChecked() {
        return this.valueFromProp.cryogenic_liquids
          && this.valueFromProp.cryogenic_liquids.includes('LHe');
      },
    },
  };
</script>

<style scoped>
  .invalid {
    border: 2px solid red;
    border-radius: 5px;
  }

  .detection_modes {
    margin-bottom: 25px;
  }

  .flex-div {
    display: flex;
    flex-flow: column nowrap;
  }
</style>
