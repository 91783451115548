import { between, maxLength, numeric, required, requiredIf } from 'vuelidate/lib/validators';
import { is_no_empty_field } from '@/assets/validators/validator_helpers';
import beamlineSpecification from '@/json/beamlineSpecification';
import { notation_number_regex } from '@/assets/validators/regex_validator';

const P_SPEC = beamlineSpecification.phelix_specification;

const bm_phelix_validator = {
  valueFromProp: {
    photon_energy_from: {
      required,
      numeric,
      is_smaller_than() {
        return parseInt(this.valueFromProp.photon_energy_from, 10)
          <= parseInt(this.valueFromProp.photon_energy_to, 10);
      },
      between: between(
        P_SPEC.energy_range.energy_range_from,
        P_SPEC.energy_range.energy_range_to,
      ),
    },
    photon_energy_to: {
      required,
      numeric,
      is_greater_than() {
        return parseInt(this.valueFromProp.photon_energy_to, 10)
          >= parseInt(this.valueFromProp.photon_energy_from, 10);
      },
      between: between(
        P_SPEC.energy_range.energy_range_from,
        P_SPEC.energy_range.energy_range_to,
      ),
    },
    photon_polarisation: { required },
    detection_modes: { required },
    measurement_temperature_from: {
      required,
      numeric,
      is_smaller_than() {
        return parseInt(this.valueFromProp.measurement_temperature_from, 10)
          <= parseInt(this.valueFromProp.measurement_temperature_to, 10);
      },
      between: between(
        P_SPEC.temperature_range.temperature_range_from,
        P_SPEC.temperature_range.temperature_range_to,
      ),
    },
    measurement_temperature_to: {
      required,
      numeric,
      is_greater_than() {
        return parseInt(this.valueFromProp.measurement_temperature_to, 10)
          >= parseInt(this.valueFromProp.measurement_temperature_from, 10);
      },
      between: between(
        P_SPEC.temperature_range.temperature_range_from,
        P_SPEC.temperature_range.temperature_range_to,
      ),
    },
    cryogenic_liquids: { required },
    lhe_use_justification: {
      requiredIf: requiredIf(function isLHeSelected() {
        if (this.valueFromProp.cryogenic_liquids) {
          return this.valueFromProp.cryogenic_liquids.includes('LHe');
        }
        return false;
      }),
      maxLength: maxLength(300),
    },
    preparation_in_situ: { required },
    preparation_temperature_from: {
      requiredIf: requiredIf((val) => val.preparation_in_situ),
      numeric,
      is_smaller_than() {
        if (!this.valueFromProp.preparation_in_situ) {
          return true;
        }
        return parseInt(this.valueFromProp.preparation_temperature_from, 10)
          <= parseInt(this.valueFromProp.preparation_temperature_to, 10);
      },
      between: between(
        P_SPEC.sample_temperature_in_situ.temperature_range_from,
        P_SPEC.sample_temperature_in_situ.temperature_range_to,
      ),
    },
    preparation_temperature_to: {
      requiredIf: requiredIf((val) => val.preparation_in_situ),
      numeric,
      is_greater_than() {
        if (!this.valueFromProp.preparation_in_situ) {
          return true;
        }
        return parseInt(this.valueFromProp.preparation_temperature_to, 10)
          >= parseInt(this.valueFromProp.preparation_temperature_from, 10);
      },
      between: between(
        P_SPEC.sample_temperature_in_situ.temperature_range_from,
        P_SPEC.sample_temperature_in_situ.temperature_range_to,
      ),
    },
    evaporation: { required },
    evaporation_data: {
      requiredIf(val) {
        if (this.valueFromProp.evaporation) {
          return is_no_empty_field(val);
        }
        return true;
      },
    },
    ar_sputtering: { required },
    crystal_clever: { required },
    reaction_chamber: { required },
    reaction_chamber_data: {
      requiredIf: requiredIf((val) => val.reaction_chamber),
      notEmptyField(val) {
        if (this.valueFromProp.reaction_chamber && val) {
          return is_no_empty_field(val.reduce((acc, el) => acc.concat([Object.values(el)]), []));
        }
        return true;
      },
      regexValidation(val) {
        if (this.valueFromProp.reaction_chamber && val) {
          const gas_pressures = val.reduce((acc, el) => acc.concat([Object.values(el)]), [])
            .map((el) => el[1]);
          return gas_pressures.every((el) => notation_number_regex.regex.test(el));
        }
        return true;
      },
      rangeValidation(val) {
        if (this.valueFromProp.reaction_chamber && val) {
          const gas_pressures = val.reduce((acc, el) => acc.concat([Object.values(el)]), [])
            .map((el) => el[1]);
          if (!gas_pressures.every((el) => {
            el = Number(el);
            return el <= P_SPEC.reaction_chamber_gas_pressure.to && el
              >= P_SPEC.reaction_chamber_gas_pressure.from;
          })) {
            return false;
          }

          const temperature = val.reduce((acc, el) => acc.concat([Object.values(el)]), [])
            .map((el) => el[2]);
          if (!temperature.every((el) => el <= P_SPEC.reaction_chamber_temperature.to && el
            >= P_SPEC.reaction_chamber_temperature.from)) {
            return false;
          }
        }
        return true;
      },
    },
  },
};
export { bm_phelix_validator };
